import React, { useState, createContext, useEffect } from "react"
import { v4 as uuidv4 } from "uuid"

const CartContext = createContext()

const CartContextProvider = props => {
  const [cart, setCart] = useState([])

  const addItem = (title, size, quantity, price, directory) => {
    setCart([
      ...cart,
      { title, size, quantity, price, directory, id: uuidv4() },
    ])
  }

  const removeItem = id => {
    setCart(cart.filter(item => item.id !== id))
  }

  useEffect(() => {
    setCart(() => {
      const localData = localStorage.getItem("myCart")
      return localData ? JSON.parse(localData) : []
    })
  }, [])

  useEffect(() => {
    localStorage.setItem("myCart", JSON.stringify(cart))
  }, [cart])

  return (
    <CartContext.Provider value={{ cart, setCart, addItem, removeItem }}>
      {props.children}
    </CartContext.Provider>
  )
}

export default CartContext

export { CartContextProvider }
