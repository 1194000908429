import React from "react"

import { CartContextProvider } from "./src/contexts/CartContext"

import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)

export const wrapRootElement = ({ element }) => (
  <Elements stripe={stripePromise}>
    <CartContextProvider>{element}</CartContextProvider>
  </Elements>
)
